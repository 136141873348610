import React from "react";

// antd
import { Row, Col, Collapse } from "antd";

// component
import FrontDivJcoin from "src/components/Front/Divider/DivJcoin";

const Section4 = () => {
  return (
    <>
      <Row justify="center" style={{ marginTop: 70 }}>
        <Col span={20} id="FAQ">
          <FrontDivJcoin title={"　常見問題　"} />
        </Col>
        <Col span={20} md={20} sm={20} xs={20}>
          <Collapse className="jetdai-faq">

            <Collapse.Panel
              header="Q1. 只要備齊所有文件就一定會核准通過嗎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line mb-none">
                會依據您的整體條件進行審核，備妥文件可幫助您加分，不保證一定核准。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q2. 我現在送出申請的話，甚麼時候可以拿到錢？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line mb-none">
                申請後審核最快30分鐘會有結果，確認核准對保完成後，一個工作天(不含例假日)即會撥款入帳。
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q3. 我該怎麼參加抽獎？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line mb-none">
                申辦【尾牙加碼方案】且綁定Line快速登入即參加抽獎。<br />
                  {/* 請聯繫Line線上客服人員協助，
                  <a href="https://lin.ee/E7mTEiS" target="_blank" rel="noreferrer">https://lin.ee/E7mTEiS</a> */}
                </p>
              </div>
            </Collapse.Panel>

            <Collapse.Panel
              header="Q4. 我已經完成任務了為什麼沒有獎勵？"
            >
              <div className="faq-expanded-item">
                <p className="pre-line mb-none">
                獎勵數量有限，發完為止。<br />
                  {/* 請聯繫Line線上客服人員協助，
                  <a href="https://lin.ee/E7mTEiS" target="_blank" rel="noreferrer">https://lin.ee/E7mTEiS</a> */}
                </p>
              </div>
            </Collapse.Panel>

          </Collapse>
        </Col>
      </Row>
    </>
  );
}

export default Section4;
